import React from "react";
import { HowIWorkHero } from "../components/HowIWork/HowIWorkHero/HowIWorkHero";
import { MyCertificates } from "../components/HowIWork/MyCertificates/MyCertificates";
import { Reviews } from "../components/HowIWork/Reviews/Reviews";
import Layout from "../components/Shared/Layout/Layout";
import { MessageFormSection } from "../components/Shared/MessageFormSection/MessageFormSection";

export interface HowIWorkPageProps {
  className?: string;
}

export const HowIWorkPage = (props: HowIWorkPageProps) => {
  const { className = "" } = props;
  return (
    <Layout className={`${className}`}>
      <HowIWorkHero />
      <MyCertificates />
      <Reviews />
      <div className="mt-20">
        <MessageFormSection />
      </div>
    </Layout>
  );
};

export default HowIWorkPage;
