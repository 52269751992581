import { Box } from "@chakra-ui/layout";
import GatsbyImage from "gatsby-image";
import React from "react";
import { useContactInfo } from "../../../../lib/hooks/useContactInfo";
import { useLeftSmileImage } from "../../../Shared/hooks/useLeftSmileImage";

export interface HowIWorkHeroImageProps {
  className?: string;
}

export const HowIWorkHeroImage = (props: HowIWorkHeroImageProps) => {
  const { className = "" } = props;
  const image = useLeftSmileImage();
  const { phone, email, address } = useContactInfo();

  return (
    <Box maxWidth={'400px'}>
      <div className="image-container w-60 sm:w-72 lg:w-80 ml-auto z-0 relative">
        <GatsbyImage loading="eager" fluid={image} />
      </div>
      <Box  className="panel h-16 bg-secondary z-20 relative -mt-14 text-white flex items-center justify-center space-x-2 font-regular text-lg lg:text-xl">
        <span>Kontaktujte ma:</span>
        <span>
          <a
            className="text-primary font-medium"
            href={`tel:${phone}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {phone}
          </a>
        </span>
      </Box>
    </Box>
  );
};
