import { Wrap } from "@chakra-ui/layout";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Container } from "../../Shared/Container/Container";
import { SectionHeader } from "../../Shared/SectionHeader/SectionHeader";
import { useMyCertificates } from "./hooks/useMyCertificates";

export interface MyCertificatesProps {
  className?: string;
}

export const MyCertificates = (props: MyCertificatesProps) => {
  const { className = "" } = props;
  const certificates = useMyCertificates();

  return (
    <section className={`${className} pb-20 pt-16 bg-section`}>
      <Container>
        <SectionHeader
          className="text-center"
          subtitle="Kariérne ocenenia"
          title="MOJE KARIÉRNE OCENENIA"
          description="Som presvedčený, že odbornosť a profesionalita je kľúčom k spokojnosti klientov"
        />
      </Container>
      <div className="certificates mt-16 items-center justify-center flex flex-wrap lg:flex-nowrap px-6">
        <Wrap justify="center" spacing="8">
          <StaticImage
            alt="Februar"
            width={213}
            height={302}
            quality={90}
            src="assets/februar.png"
            className="shadow-xl"
          />
          <StaticImage
            alt="August"
            width={213}
            height={302}
            quality={90}
            className="shadow-xl"
            src="assets/remax_DIPLOM_MAKLER_AUGUST 1.png"
          />
          <StaticImage
            alt="1. stupen"
            width={428}
            height={302}
            quality={90}
            className="shadow-xl"
            src="assets/certifikát-2-stupeň 1.png"
          />

          <StaticImage
            alt="2.stupen"
            width={428}
            height={302}
            quality={90}
            className="shadow-xl"
            src="assets/Jaroslav-Virgovič 1.png"
          />
        </Wrap>
      </div>
    </section>
  );
};
