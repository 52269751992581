import React from "react";
import { Container } from "../../Shared/Container/Container";
import { List } from "../../Shared/List/List";
import { SectionHeader } from "../../Shared/SectionHeader/SectionHeader";
import { HowIWorkHeroDetail } from "./components/HowIWorkHeroDetail";
import { HowIWorkHeroImage } from "./components/HowIWorkHeroImage";
import { HowIWorkHeroText } from "./components/HowIWorkHeroText";

export interface HowIWorkHeroProps {
  className?: string;
}

export const HowIWorkHero = (props: HowIWorkHeroProps) => {
  const { className = "" } = props;

  return (
    <section className={`${className} py-16`}>
      <Container className="flex lg:space-x-10 flex-wrap lg:flex-nowrap">
        <div className="image w-full lg:w-4/12 order-2 lg:order-1 mt-12 lg:mt-0">
          <HowIWorkHeroImage />
          <HowIWorkHeroDetail />
        </div>
        <div className="info order-1 lg:order-2 w-full  lg:w-8/12">
          <SectionHeader
            title="VYŽADUJETE INDIVIDUÁLNY PRÍSTUP?"
            subtitle="Ako pracujem?"
            description="Poskytujem klientom vysoký štandard služieb"
          />
          <div className="items mt-8">
            <List
              items={[
                "Dôkladná cenová analýza nehnuteľnosti na základe osobného stretnutia s Vami. Odborne posúdim stav a trhovú cenu nehnuteľnosti.",
                "Profesionálne odprezentujem Vašu nehnuteľnosť čo zaručí predaj za najvyššiu možnú cenu. Možnosť využitia licitačného systému.",
                "Chránim Vás v priebehu celého procesu predaja, aby Vám peniaze bezpečne prišli na účet. Kvalitné právne služby sú samozrejmosťou.",
              ]}
            />
          </div>
          <div className="text mt-8">
            <HowIWorkHeroText />
          </div>
        </div>
      </Container>
    </section>
  );
};
