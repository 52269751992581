import React from "react";
import { Container } from "../../Shared/Container/Container";
import { SectionHeader } from "../../Shared/SectionHeader/SectionHeader";
import { SingleReview } from "./components/SingleReview";
import { useReviews } from "./hooks/useReviews";

export interface ReviewsProps {
  className?: string;
}

export const Reviews = (props: ReviewsProps) => {
  const { className = "" } = props;
  const reviews = useReviews();

  return (
    <section className={`${className} py-16`}>
      <Container>
        <SectionHeader
          className="text-center"
          subtitle="Referencie"
          title="SPOKOJNOSŤ NA OBOCH STRANÁCH"
          description="Vysoký štandard služieb ocenia predávajúci aj kupujúci"
        />
        <div className="reviews mt-12 space-y-14">
          {reviews.map((review) => (
            <SingleReview key={review.title} {...review} />
          ))}
        </div>
      </Container>
    </section>
  );
};
