import { Link } from "gatsby";
import React from "react";
import { Button } from "../../../Shared/Button/Button";
import { List } from "../../../Shared/List/List";

export interface HowIWorkHeroDetailProps {
  className?: string;
}

export const HowIWorkHeroDetail = (props: HowIWorkHeroDetailProps) => {
  const { className = "" } = props;
  return (
    <div className="detail mt-10">
      <h5 className="text-xl font-medium">
        Ako prebieha proces začatia spolupráce?
      </h5>
      <p className="mt-4">
        Ku každému klientovi pristupujem profesionálne a dbám na vysoký štandard
        mnou ponúkaných služieb.
      </p>
      <List
        space={6}
        type="numbered"
        className="mt-8"
        items={[
          "Osobné stretnutie a pochopenie požiadaviek",
          "Cenová analýza a príprava stratégie predaja",
          "Dohodnutie podmienok spolupráce",
        ]}
      />
      <div className="mt-8">
        <Link to={"#kontakt-form"}>
          <Button>MÁM ZÁUJEM O KONZULTÁCIU</Button>
        </Link>
      </div>
    </div>
  );
};
