import React, { ReactNode } from "react";
import { FiCheck } from "react-icons/fi";
import { listItem } from "./listitem.module.scss";

export interface ListItemProps {
  className?: string;
  type?: "check" | "numbered" | "empty";
  number?: number;
  label: string | ReactNode[];
}

export const ListItem = (props: ListItemProps) => {
  const { className = "", type = "check", number, label } = props;
  return (
    <div className={`${className} flex space-x-4 items-center`}>
      <div
        style={{
          minWidth: 32,
          minHeight: 32,
        }}
        className="bullet bg-primary flex justify-center items-center text-white font-medium text-xl"
      >
        {type === "check" && <FiCheck />}
        {type === "numbered" && number}
        {type === "empty" && <FiCheck />}
      </div>
      <div className={listItem}>{label}</div>
    </div>
  );
};
