import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import { routes } from "../../../../lib/routes";
import { Review } from "../hooks/useReviews";
import { SingleReviewArrowsCard } from "./SingleReviewArrowsCard";

export interface SingleReviewProps extends Review {
  className?: string;
}

const serviceMap = {
  selling: {
    link: `${routes.realEstateServices.to}#predaj`,
    label: "Chcete predať nehnuteľnosť?",
  },
  buying: {
    link: `${routes.realEstateServices.to}#kupa`,
    label: "Chcete kúpiť nehnuteľnosť?",
  },
  valuation: {
    link: `${routes.realEstateServices.to}#ocenenie`,
    label: "Chcete naceniť vašu nehnuteľnosť?",
  },
};

export const SingleReview = (props: SingleReviewProps) => {
  const { service, className = "", text, title, image } = props;
  return (
    <article className={`${className} flex lg:space-x-14 flex-col lg:flex-row`}>
      <div className="image lg:w-1/3 shadow-lg">
        <GatsbyImage className="h-full" alt="title" image={getImage(image)} />
      </div>
      <SingleReviewArrowsCard className="text lg:w-2/3">
        <h5 className="text-2xl font-medium text-primary">{title}</h5>
        <p className="mt-5 text-lg">{text}</p>
        <Link
          to={serviceMap[service].link}
          className="link mt-4 inline-flex underline text-lg font-medium"
        >
          {serviceMap[service].label}
        </Link>
      </SingleReviewArrowsCard>
    </article>
  );
};
