import React from "react";
import { ReactNode } from "react-markdown";
import Arrow from "../assets/Intersect.svg";

export interface SingleReviewArrowsCardProps {
  className?: string;
  children?: ReactNode[] | ReactNode;
}

export const SingleReviewArrowsCard = (props: SingleReviewArrowsCardProps) => {
  const { className = "", children } = props;
  return (
    <div className={`relative bg-white shadow-lg ${className} p-10`}>
      <div className="leftarrow absolute -top-6 -left-6">
        <img src={Arrow} alt="" />
      </div>
      <div className="rightarrow absolute -bottom-6 -right-6">
        <img src={Arrow} alt="" className=" rotate-180 transform" />
      </div>

      <div className="content">{children}</div>
    </div>
  );
};
