import { graphql, useStaticQuery } from "gatsby";

export interface Certificate {
  name: string;
  image: any;
}

export const useMyCertificates = (): Certificate[] => {
  const {
    strapiHowIWorkPage: { certificates },
  } = useStaticQuery(graphql`
    query useMyCertificates {
      strapiHowIWorkPage {
        certificates {
          name
          image {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FIXED
                transformOptions: { fit: COVER }
              )
            }
          }
        }
      }
    }
  `);

  return certificates;
};
