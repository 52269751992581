import { Link } from "gatsby";
import React from "react";
import { routes } from "../../../../lib/routes";

export interface HowIWorkHeroTextProps {
  className?: string;
}

export const HowIWorkHeroText = (props: HowIWorkHeroTextProps) => {
  const { className = "" } = props;
  return (
    <div className={`${className}`}>
      <h4 className="font-medium text-xl mb-3">
        Osobné stretnutie a pochopenie požiadaviek
      </h4>
      <p>
        Osobné stretnutie je dôležité. Ak mám{" "}
        <Link
          className="text-primary font-medium"
          to={`${routes.realEstateServices.to}#predaj`}
        >
          predať
        </Link>{" "}
        Vašu nehnuteľnosť za najvyšiu možnú cenu, potrebujem pochopiť Vaše
        potreby a spísať všetky informácie o nehnuteľnosti, ktoré mi môžete
        poskytnúť iba Vy, keďže poznáte svoju nehnuteľnosť najlepšie. V prípade
        že chcete{" "}
        <Link
          className="text-primary font-medium"
          to={`${routes.realEstateServices.to}#kupa`}
        >
          kúpiť
        </Link>{" "}
        nehnuteľnosť, osobné stretnutie je taktiež prvým krokom.
      </p>

      <h4 className="font-medium text-xl mb-3 mt-6">
        Cenová analýza a príprava stratégie predaja
      </h4>
      <p className="">
        Odborne{" "}
        <Link
          className="text-primary font-medium"
          to={`${routes.realEstateServices.to}#ocenenie`}
        >
          oceniť nehnuteľnosť
        </Link>{" "}
        a správne nastaviť predajnú cenu je kľúčový krok. Ak sa cena prestrelí,
        tak nepredáte a ak sa podhodnotí, tak predáte pod cenu. Preto venujem
        cenovej analýze a príprave stratégie predaja Vašej nehnuteľnosti
        dostatok času.
      </p>

      <h4 className="font-medium text-xl mb-3 mt-6">
        Dohodnutie podmienok spolupráce
      </h4>
      <p>
        Po dohodnutí podmienok spolupráce následuje fotenie s našim
        profesionálnym fotografom, ktorého vycvičené oko v kombinácii s home
        staging zachytí to najlepšie z vašej nehnuteľnosti. Kvalitné spracovanie
        inzercie pritiahne väčší počet záujemcov a je jednou s viacerých častí
        stratégie, ako predať Vašu nehnuteľnosť za najvyššiu cenu.
      </p>
    </div>
  );
};
