import { graphql, useStaticQuery } from "gatsby";

export interface Review {
  title: string;
  text: string;
  image: any;
  service: "buying" | "selling" | "valuation";
}

export const useReviews = (): Review[] => {
  const {
    strapiHowIWorkPage: { Review },
  } = useStaticQuery(graphql`
    query useReviews {
      strapiHowIWorkPage {
        Review {
          title
          service
          text
          image {
            childImageSharp {
              gatsbyImageData(
                quality: 90
                layout: FULL_WIDTH
                transformOptions: { fit: CONTAIN }
              )
            }
          }
        }
      }
    }
  `);

  return Review;
};
